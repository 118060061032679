<template></template>
<script setup lang="ts">
import { type Script } from '@unhead/schema'
import { contentTypes } from '~/models';

const siteStore = useSiteStore()
let scripts = Array<Script>()


siteStore.scriptTags.forEach((script, idx) => {
    const scriptModel: Script = {}

    const keyId = `script-${idx}`;
    scriptModel.key = keyId;
    scriptModel.id = keyId;
    if (script.elements.innerCode.value.length > 0) {
        scriptModel.innerHTML = removeScriptTag(script.elements.innerCode.value)

        const attributes = getAttributesFromText(script.elements.innerCode.value);
        attributes.forEach(at => {
            scriptModel[at.key] = at.value
        })
        
    }


    if (script.elements.url.value.length > 0)
        scriptModel.src = script.elements.url.value

    scriptModel.tagPosition = script.elements.location.value.length > 0 ? getPosition(script.elements.location.value[0].codename) : 'head'

    scripts.push(scriptModel)
})


if (scripts.length > 0) {
    useHead({
        script: scripts
    })
}

function getPosition(value: string) {
    switch (value) {
        case contentTypes.script_tag.elements.location.options.head.codename:
            return 'head'
        case contentTypes.script_tag.elements.location.options.body_close.codename:
            return 'bodyClose'
        case contentTypes.script_tag.elements.location.options.body_open.codename:
            return 'bodyOpen'
        default:
            return 'head'
    }
}

function removeScriptTag(value: string) {
    return value.replace(/<script.*?>|<\/script>/g, '')
}

function getAttributesFromText(inputText: string) {
    const attributes: { key: string, value: string }[] = [];
    const regex = /([\w-]+)\s*=\s*["']([^"']+)["']/g;
    let match;

    while ((match = regex.exec(inputText)) !== null) {
        attributes.push({ key: match[1], value: match[2] });
    }

    return attributes;
}
</script>